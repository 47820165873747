import { useEffect } from 'react'
import Thumbnail from '../components/Thumbnail'
import { useFriends } from '../hooks/useFriends'
import { useCollection } from '../hooks/useCollection'

export default function Friends() {
  const { documents, error } = useCollection('users')

  const { getFriendRequestsOut, getFriendRequestsIn, setFriendRequest } = useFriends()

  const handleFetchOutgoing = async (e) => {
    const result = await getFriendRequestsOut()
    console.log(result)
  }

  const handleFetchIncoming = async (e) => {
    const result = await getFriendRequestsIn()
    console.log(result)
  }  

  const handleFriendRequest = async (frienduid) => {
    const result = await setFriendRequest(frienduid)
    console.log(result)
  }

  return (<div>

    <h2>Friends</h2>

    <h4>All Users</h4>

    { documents &&
      <ul className='items-v list'>

        { documents.map((user) => (
          <li>
            <Thumbnail uid={user.id} />
            <span className='spread'>
              &nbsp;{user.displayName} - "{user.catchPhrase}"
            </span>
            <button className='small' onClick={() => {handleFriendRequest(user.id)}}>Request Friendship</button>
          </li>
        ))}

      </ul>
    }

    <h4>Outgoing Friend Requests</h4>
    <button onClick={handleFetchOutgoing}>Fetch Outgoing</button>
    <h4>Incoming Friend Requests</h4>
    <button onClick={handleFetchIncoming}>Fetch Incoming</button>
    <h4>Completed Friend Requests</h4>


  </div>)
}
