
import { firestore, timestamp } from "../firebase/config"
import { useAuthContext } from "./useAuthContext"

export const useFriends = () => {
	const { user } = useAuthContext()

  const getFriendRequestsOut = async () => {
    if (user) {
      try {
        // Get all requests
        const ref = firestore.collection('users/' + user.uid + '/requestsOut')
        const snapshot = await ref.get()

        let results = []
        snapshot.docs.forEach(async (doc) => {
          // Get cross reference data and merge
          const xref = firestore.collection('users')
          const xsnapshot = await xref.doc(doc.id).get()
          results.push({ ...doc.data(), ...xsnapshot.data(), id: doc.id })
        })

        return results
      } catch (err) {
        console.log(err)
        return null
      }
    } else {
      return null
    }
  }

  const getFriendRequestsIn = async () => {
    if (user) {
      try {
        const ref = firestore.collection('users/' + user.uid + '/requestsIn')
        const snapshot = await ref.get()

        let results = []
        snapshot.docs.forEach(async (doc) => {
          // Get cross reference data and merge
          const xref = firestore.collection('users')
          const xsnapshot = await xref.doc(doc.id).get()
          results.push({ ...doc.data(), ...xsnapshot.data(), id: doc.id })
        })
        
        return results
      } catch (err) {
        console.log(err)
        return null
      }
    } else {
      return null
    }
  }

  const setFriendRequest = async (frienduid) => {
    if (user) {
      try {
        const created = timestamp.fromDate(new Date())

        // Create outgoing request reference for user
        const refOut = firestore.collection('users/' + user.uid + '/requestsOut')
        const resOut = await refOut.doc(frienduid).set({ created })

        // Create incoming request reference for friend
        const refIn = firestore.collection('users/' + frienduid + '/requestsIn')
        const resIn = await refIn.doc(user.uid).set({ created })

        return true
      } catch (err) {
        console.log(err)
        return null
      }
    } else {
      return null
    }
  }

  const getFriends = async () => {
    if (user) {
      try {
        const ref = firestore.collection('users/' + user.uid + '/friends')
        const snapshot = await ref.get()

        let results = []
        snapshot.docs.forEach(async (doc) => {
          // Get cross reference data and merge
          const xref = firestore.collection('users')
          const xsnapshot = await xref.doc(doc.id).get()
          results.push({ ...doc.data(), ...xsnapshot.data(), id: doc.id })
        })
        
        return results
      } catch (err) {
        console.log(err)
        return null
      }
    } else {
      return null
    }
  }

  const setFriend = async (frienduid) => {
    if (user) {
      try {
        const created = timestamp.fromDate(new Date())

        // Verify there is an active request in for the user
        const refIn = firestore.collection('users/' + user.uid + '/requestsIn')
        const docIn = refIn.doc(frienduid)
        const resIn = await docIn.get()

        // Verify there is an active request out for the friend
        const refOut = firestore.collection('users/' + frienduid + '/requestsOut')
        const docOut = refOut.doc(user.uid)
        const resOut = await docOut.get()

        // Check verification
        if (resIn.data() && resOut.data()) {
          // Create friend for user
          const refUser = firestore.collection('users/' + user.uid + '/friends')
          const resUser = await refUser.doc(frienduid).set({ created })

          // Create incoming request reference for friend
          const refFriend = firestore.collection('users/' + frienduid + '/friends')
          const resFriend = await refFriend.doc(user.uid).set({ created })
          
          // Delete requests
          await docIn.delete()
          await docOut.delete()
        }

        return true
      } catch (err) {
        console.log(err)
        return null
      }
    } else {
      return null
    }
  }  

  return { getFriendRequestsOut, getFriendRequestsIn, setFriendRequest, getFriends, setFriend }
}
