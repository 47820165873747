import { useEffect, useState } from "react"
import ProjectsForm from "../components/ProjectsForm"
import ProjectsList from "../components/ProjectsList"
import { firestore } from "../firebase/config"
import { useAuthContext } from "../hooks/useAuthContext"

export default function Projects() {
  const { user, profile } = useAuthContext()
  const [myProjects, setMyProjects] = useState([])

  const updateProjects = async () => {
    if (profile?.projects) {
      try {
        let projects = profile.projects
        if (!projects) { projects = [] }

        let results = []
        await Promise.all(projects.map(async (project) => {
          // Get cross reference data and merge
          const ref = firestore.collection('projects')
          const snapshot = await ref.doc(project).get()
          results.push({ ...snapshot.data(), id: snapshot.id })
        }))

        setMyProjects(results)
      } catch (err) {
        setMyProjects([])
      }
    } else {
      setMyProjects([])
    }
  }

  useEffect(() => {
    updateProjects()
  }, [profile])

  return (<div>
    <h2>Projects</h2>

    <h4>New Project</h4>
    <ProjectsForm />
    <br/>

    <h4>My Projects</h4>
    { myProjects && <ProjectsList projects={myProjects} /> }

  </div>)
}
