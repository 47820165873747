
export default function Home() {
	return (<div>
	
		<h2>Home</h2>

		<center>
			<br/><br/>
			<h1>Welcome to LifeSync</h1>
			<br/>
		</center>

	</div>)
}
