import { useEffect } from "react"

export default function Notice(props) {
  
  if (props.children) {
    if (props.type) {
      return ( <p className={"notice " + props.type}>{ props.children }</p> )
    } else {
      return ( <p className={"notice"}>{ props.children }</p> )
    }
  }
  return ''
}
