import { useParams } from "react-router-dom"
import { useCollection } from "../hooks/useCollection"
import { useDocument } from "../hooks/useDocument"

export default function Project() {
  const { pid } = useParams()
  const { document: project, error: projectErr } = useDocument('projects', pid)

  if (projectErr) {
    return (<div>
      <h2>Error</h2>
      { projectErr }
    </div>)
  }

  if (!project) {
    return (<div>
      <h2>Loading...</h2>
    </div>)
  }  

  console.log('pid', pid)
  console.log("project", project)

  return (<div>
    <h2>Project - { project.name }</h2>

    <h4>Transactions</h4>

    <ul>
    { project.transactions && project.transactions.map((transaction) => (
      <li>{ transaction.name }: { transaction.cost }</li>
    ))}
    { !project.transactions && (
      <li>No transactions available.</li>
    )}
    </ul>

  </div>)
}
