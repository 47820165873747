import { firefunctions } from "../firebase/config"
import { createWorker } from "tesseract.js"
import * as d3 from "d3"

// https://www.html5rocks.com/en/tutorials/canvas/imagefilters/

let ReyTest = {}

ReyTest.loadFromImage = function (image) {
  // Make a canvas and set the size to match the image
  this.canvas = document.createElement('canvas')
  this.canvas.width = image.width
  this.canvas.height = image.height

  // Get the context of the canvas and draw to it
  this.context = this.canvas.getContext('2d')
  this.context.drawImage(image, 0, 0)
  this.getPixels()
}

ReyTest.drawToCanvas = function (canvas, scale_x=1, scale_y=1) {
  const w = this.canvas.width * scale_x
  const h = this.canvas.height * scale_y
  canvas.width = w
  canvas.height = h
  var context = canvas.getContext('2d')
  context.drawImage(this.canvas, 0, 0, w, h)
}


ReyTest.getPixels = function () {
  // Return the pixels (imageData)
  this.imageData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
  return this.imageData
}

ReyTest.setPixels = function (imageData) {
  this.imageData = imageData
  this.context.putImageData(this.imageData, 0, 0)
}

ReyTest.filterGrayscale = function (imageData) {
  var d = imageData.data
  for (var i = 0; i < d.length; i += 4) {
    var r = d[i+0]
    var g = d[i+1]
    var b = d[i+2]

    // CIE luminance for the RGB
    // The human eye is bad at seeing red and blue, so we de-emphasize them.
    //var v = 0.2126*r + 0.7152*g + 0.0722*b
    var v = ( (r + g + b) / 3 )
    d[i+0] = d[i+1] = d[i+2] = parseInt(v)
  }
  return imageData
}

ReyTest.convolution = function (imageData) { //, weights) {
  const matrix = [
    [ 1/9, 1/9, 1/9],
    [ 1/9, 1/9, 1/9],
    [ 1/9, 1/9, 1/9]
  ]

  var matrixSize = matrix.length
  var matrixHalfSize = Math.floor(matrixSize / 2)

  var src = imageData.data
  var w = imageData.width
  var h = imageData.height

  var tempCanvas = document.createElement('canvas')
  var tempContext = tempCanvas.getContext('2d')
  var tempImageData = tempContext.createImageData(w, h)
  var dst = tempImageData.data;

  // For each pixel in the image
  for (var y = 0; y < h; y++) {
    for (var x = 0; x < w; x++) {

      // Destination image index position
      var dstIndex = (y * w + x) * 4

      // Reset values
      var r=0, g=0, b=0

      // For each pixel in the matrix
      for (var m = 0; m < matrixSize; m++) {    // y
        for (var n = 0; n < matrixSize; n++) {  // x

          // Center the affected pixel in the matrix
          var srcY = y + m - matrixHalfSize
          var srcX = x + n - matrixHalfSize

          // Source pixel is within the image boundaries
          if (srcY >= 0 && srcY < h && srcX >= 0 && srcX < w) {
            // Source image index position
            var srcIndex = (srcY * w + srcX) * 4

            // Calculate the weight!
            var weight = matrix[m][n]
            r += src[srcIndex+0] * weight
            g += src[srcIndex+1] * weight
            b += src[srcIndex+2] * weight
          }
        }
      }

      dst[dstIndex+0] = r;
      dst[dstIndex+1] = g;
      dst[dstIndex+2] = b;
      dst[dstIndex+3] = 255;
    }
  }

  console.log("Complete")

  return tempImageData
}


ReyTest.filterColor = function (imageData, threshold) {
  var d = imageData.data
  for (var i = 0; i < d.length; i += 4) {
    var r = d[i+0]
    var g = d[i+1]
    var b = d[i+2]

    var x = Math.abs(r-g)
    var y = Math.abs(g-b)
    var z = Math.abs(b-r)

    if (x > threshold || y > threshold || z > threshold) {
      d[i+0] = d[i+1] = d[i+2] = 255
    }
  }
  return imageData
}

ReyTest.filterCutoff = function (imageData, cutoff) {
  var d = imageData.data
  for (var i = 0; i < d.length; i += 4) {
    if (d[i+0] <= cutoff) {
      d[i+0] = d[i+1] = d[i+2] = 0
    } else {
      d[i+0] = d[i+1] = d[i+2] = 255
    }
  }
  return imageData
}


ReyTest.tesseract = function () {
  const worker = createWorker({
    logger: m => console.log(m), // Add logger here
  });

  (async () => {
    await worker.load();
    await worker.loadLanguage('eng')
    await worker.initialize('eng')

    const results = await worker.recognize(this.canvas.toDataURL(null, 1))
    console.log(results.data.text)
    await worker.terminate()

    this.tesseractResults = results
  })()
}

ReyTest.tesseractSymbols = function () {
  const symbols = this?.tesseractResults?.data?.symbols
  const context = this.context

  // SYMBOLS
  symbols?.map((symbol) => {
    //console.log(symbol.bbox)

    const x = symbol.bbox.x0
    const y = symbol.bbox.y0
    const w = symbol.bbox.x1 - x
    const h = symbol.bbox.y1 - y

    context.beginPath()
    context.fillStyle = "rgba(255, 0, 0, 0.3)"
    context.fillRect(x, y, w, h)
  })
}


//****************************************************************
// Histogram

ReyTest.histogram = function () {

  const histogramData = new Array(256)
  for (var i = 0; i < histogramData.length; i++) {
    histogramData[i] = 0
  }

  const d = this.imageData.data
  for (var i = 0; i < d.length; i += 4) {
    const v = d[i+0]
    histogramData[v]++
  }


  const newHistogramData = []
  for (var i = 0; i < histogramData.length; i++) {
    newHistogramData[i] = { k: i, v: histogramData[i] }
  }


// set the dimensions and margins of the graph
var margin = {top: 10, right: 30, bottom: 30, left: 60},
    width = 460 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom;

// append the svg object to the body of the page
var svg = d3.select("#histogram")
  .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
  .append("g")
    .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")");

  // Add X axis
  var x = d3.scaleLinear()
    .domain([0, 255])
    .range([ 0, width ]);
  svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x));

  // Add Y axis
  var y = d3.scaleLinear()
    .domain([0, 200000])
    .range([ height, 0]);
  svg.append("g")
    .call(d3.axisLeft(y));

  // Add dots
  svg.append('g')
    .selectAll("dot")
    .data(newHistogramData)
    .enter()
    .append("circle")
      .attr("cx", function (d) { return x(d.k) } )
      .attr("cy", function (d) { return y(d.v) } )
      .attr("r", 1.5)
      .style("fill", "#69b3a2")


}

//****************************************************************









export default function Test() {
  const sayHello = firefunctions.httpsCallable('sayHello')

  const handleFunction = (e) => {
    e.preventDefault()

    sayHello({ greeting: "howdy", name: "Tom" }).then(result => {
      console.log(result.data)
    })
  }


  const handleReyTestStep = (step) => {
    console.log("Running Step: " + step)

    let can
    let p

    switch (step) {
      case 100:

        ReyTest.tesseract()
        
        break
      case 101:
        
        ReyTest.tesseractSymbols()
        can = document.getElementById("canvasStep1")
        ReyTest.drawToCanvas(can, 0.2, 0.2)
      
        break
      case 102:

        ReyTest.histogram()

        break
      case 0:
        
        const image = new Image()
        image.onload = () => {          // Set this up first before the image starts loading
          ReyTest.loadFromImage(image)
          let can = document.getElementById("canvasStep0")
          ReyTest.drawToCanvas(can, 0.2, 0.2)
        }
        image.src = "20220613_204505.jpg"

        break
      case 1:

        p = ReyTest.getPixels()
        p = ReyTest.filterGrayscale(p)
        ReyTest.setPixels(p)       
        can = document.getElementById("canvasStep0")
        ReyTest.drawToCanvas(can, 0.2, 0.2)

        break
      case 2:

        p = ReyTest.getPixels()
        p = ReyTest.filterCutoff(p, 140)
        ReyTest.setPixels(p)
        can = document.getElementById("canvasStep0")
        ReyTest.drawToCanvas(can, 0.2, 0.2)        

        break
      case 3:

        p = ReyTest.getPixels()
        p = ReyTest.filterColor(p, 75)
        ReyTest.setPixels(p)
        can = document.getElementById("canvasStep1")
        ReyTest.drawToCanvas(can, 0.2, 0.2)

        break        
      case 4:

        p = ReyTest.getPixels()
        p = ReyTest.convolution(p)
        ReyTest.setPixels(p)
        can = document.getElementById("canvasStep1")
        ReyTest.drawToCanvas(can, 0.2, 0.2)

        break                
      default:
        console.log("Invalid Step!")
        break
    }
  }

	return (<div className="wide">
	
		<h2>Test</h2>

    <div className="items-h">
      <button onClick={handleFunction}>Say Hello Function</button>
      <button onClick={() => {handleReyTestStep(100)}}>Tesseract</button>
      <button onClick={() => {handleReyTestStep(101)}}>Symbols</button>
      <button onClick={() => {handleReyTestStep(102)}}>Histogram</button>
    </div>

    <div className="items-h">
      <button onClick={() => {handleReyTestStep(0)}}>Step 0 - Load Image</button>
      <button onClick={() => {handleReyTestStep(1)}}>Step 1 - Grayscale</button>
      <button onClick={() => {handleReyTestStep(2)}}>Step 2 - Cutoff</button>
      <button onClick={() => {handleReyTestStep(3)}}>Step 3 - Color Threshold</button>
      <button onClick={() => {handleReyTestStep(4)}}>Step 4 - Convolution</button>
    </div>

    <div style={{display: "flex", overflow: "scroll"}}>
      <canvas width="500" id="canvasStep0" />
      <canvas width="500" id="canvasStep1" />
    </div>

    <div id="histogram"></div>

	</div>)
}
