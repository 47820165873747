// imports
import { useState } from 'react'
import FormElement from '../components/FormElement'
import { useSignup } from '../hooks/useSignup'

// page
export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const { signupEmail, error, pending } = useSignup()

  const handleSubmit = (e) => {
    e.preventDefault()
    signupEmail(email, password, displayName)
  }

  return (<div className='short'>

    <h2>Sign Up</h2>

    <form onSubmit={handleSubmit}>
      <FormElement label="email">
        <input 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
        />
      </FormElement>
      <FormElement label="password">
        <input 
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password} 
        />
      </FormElement>
      <FormElement label="display name">
        <input 
          type="text" 
          onChange={(e) => setDisplayName(e.target.value)}
          value={displayName}
        />
      </FormElement>

      { !pending && <button className="btn">Sign up</button>}
      { pending && <button className='btn' disabled>Signing Up...</button>}
      { error && <p className='notify_err'>{error}</p>}
    </form>

  </div>)
}