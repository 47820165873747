import { Link } from 'react-router-dom'
import { useAuthContext } from "../hooks/useAuthContext"
import { useFirestore } from "../hooks/useFirestore"

export default function ProjectsList({ projects }) {
  const { user, profile } = useAuthContext()
  const { deleteDocument } = useFirestore('projects')
  const { updateDocument } = useFirestore('users')
  
  const handleDeleteProject = async (pid) => {
    await deleteDocument(pid)

    console.log(profile)
    console.log(profile.projects)

    let userProjects = profile.projects
    if (userProjects) {
      userProjects = userProjects.filter((value, index, arr) => {
        return (value != pid)
      })
    }

    console.log(userProjects)

    const updates = {
      projects: userProjects,
    }
    updateDocument(user.uid, updates)
  }

  return (
    <ul className='items-v list'>
      { projects.map((project) => (
        <li key={project.id}>
          <div className='items-h'>
            <span>{project.name}</span>
            <span className="spread"></span>
            <Link to={"/project/" + project.id}><button className="small">View</button></Link>
            <button className="small" onClick={() => {handleDeleteProject(project.id)}}>Delete</button>
          </div>
        </li>
      ))}
    </ul>
  )
}
