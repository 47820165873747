import { useEffect, useState } from "react"
import { fireauth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogin = () => {
	const [cancelled, setCancelled] = useState(false)
	const [error, setError] = useState(null)
	const [pending, setPending] = useState(false)
	const { dispatch } = useAuthContext()

	const loginEmail = async (email, password) => {
		setError(null)
		setPending(true)

		// sign the user in
		try {
			const resp = await fireauth.signInWithEmailAndPassword(email, password)

			// dispatch login action
			dispatch({ type: 'LOGIN', payload: resp.user })

			// update state
			if (!cancelled) {
				setError(null)
				setPending(false)
			}
		}
		catch (err) {
			if (!cancelled) {
				console.log(err.message)
				setError(err.message)
				setPending(false)
			}
		}
	}

	const loginGoogle = async () => {
		setError(null)
		setPending(true)

		// sign the user in
		try {
			const provider = fireauth.GoogleAuthProvider;
			const resp = await fireauth.signInWithPopup(provider);

			// dispatch login action
			dispatch({ type: 'LOGIN', payload: resp.user })

			// update state
			if (!cancelled) {
				setError(null)
				setPending(false)
			}
		}
		catch (err) {
			if (!cancelled) {
				console.log(err.message)
				setError(err.message)
				setPending(false)
			}
		}
	}

  useEffect(() => {
    return () => { setCancelled(true) }
  }, [])	

	return { loginEmail, loginGoogle, error, pending }
}
