import { useReducer, useEffect, useState } from "react"
import { firestore, timestamp } from "../firebase/config"

let initialState = {
  document: null,
  error: null,
  pending: false,
  success: null
}

const firestoreReducer = (state, action) => {
  console.log("firestoreReducer", state, action)

  switch (action.type) {
    case 'PENDING':
      return { ...state, pending: true, document: null, success: false, error: null }
    case 'ERROR':
      return { ...state, pending: false, document: null, success: false, error: action.payload }
    case 'ADDED':
      return { ...state, pending: false, document: action.payload, success: true, error: null }
    case 'DELETED':
      return { ...state, pending: false, document: null, success: true, error: null }
    case 'UPDATED':
      return { ...state, pending: false, document: action.payload, success: true, error: null }
    case 'SETMERGE':
      return { ...state, pending: false, document: action.payload, success: true, error: null }
    case 'FETCHED':
      return { ...state, pending: false, document: action.payload, success: true, error: null }  
    default:
      return state
  }
}

export const useFirestore = (collection) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState)
  const [cancelled, setCancelled] = useState(false)

  // collection ref
  const ref = firestore.collection(collection)

  const dispatchIfNotCancelled = (action) => {
    if (!cancelled) {
      dispatch(action)
    }
  }

  const addDocument = async (doc, id) => {
    dispatch({ type: 'PENDING' })

    try {
      const created = timestamp.fromDate(new Date())
      const updated = created
      let addedDocument = null

      if (id) {
        addedDocument = await ref.doc(id).set({ ...doc, created, updated })
      }
      else
      {
        addedDocument = await ref.add({ ...doc, created, updated })
      }
      dispatchIfNotCancelled({ type: 'ADDED', payload: addedDocument })
      return addedDocument
    }
    catch (err) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
      return null
    }
  }

  const deleteDocument = async (id) => {
    dispatch({ type: 'PENDING' })

    try {
      await ref.doc(id).delete()
      dispatchIfNotCancelled({ type: 'DELETED' })
      return true
    }
    catch (err) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
      return null
    }
  }

  const updateDocument = async (id, updates) => {
    dispatch({ type: 'PENDING' })

    try {
      const updated = timestamp.fromDate(new Date())
      updates = { ...updates, updated }
      const updatedDocument = await ref.doc(id).update(updates)
      dispatchIfNotCancelled({ type: 'UPDATED', payload: updatedDocument })
      return updatedDocument
    }
    catch (err) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
      return null
    }
  }

  const setMergeDocument = async (id, updates) => {
    dispatch({ type: 'PENDING' })

    try {
      const updated = timestamp.fromDate(new Date())
      updates = { ...updates, updated }
      const updatedDocument = await ref.doc(id).set(updates, {merge:true})
      dispatchIfNotCancelled({ type: 'SETMERGE', payload: updatedDocument })
      return updatedDocument
    }
    catch (err) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
      return null
    }
  }  

  const fetchDocument = async (id) => {
    dispatch({ type: 'PENDING' })

    try {
      const fetchedDocument = await ref.doc(id)
      dispatchIfNotCancelled({ type: 'FETCHED', payload: fetchedDocument })
      return fetchedDocument
    }
    catch (err) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
      return null
    }
  }

  useEffect(() => {
    return () => { setCancelled(true) }
  }, [])

  return { addDocument, deleteDocument, updateDocument, setMergeDocument, fetchDocument, response }
}
