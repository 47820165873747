
export default function FormElement(props) {
  return (
    <>
      <label>
        <span>{ props.label }</span>
        { props.children }
      </label>
    </>
  )
}
