import { useState, useEffect } from 'react'
import { useDocument } from '../hooks/useDocument'

export default function Thumbnail({ uid }) {
  const { document: profile, error: errProfile } = useDocument('users', uid)
  const [thumbnailUrl, setThumbnailUrl] = useState('')

  useEffect(() => {
    if (profile?.thumbnail) {
      setThumbnailUrl(profile.thumbnail)
    } else {
      setThumbnailUrl('logo192.png')
    }
  }, [profile])

  return (
    <img src={thumbnailUrl} height="32" width="32" />
  )
}
