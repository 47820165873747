import { useEffect, useState } from "react"
import { fireauth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
	const [cancelled, setCancelled] = useState(false)
	const [error, setError] = useState(null)
	const [pending, setPending] = useState(false)
	const { dispatch } = useAuthContext()

	const logout = async () => {
		setError(null)
		setPending(true)

		// sign the user out
		try {
			await fireauth.signOut()

			// dispatch logout action
			dispatch({ type: 'LOGOUT' })

			// update state
			if (!cancelled) {
				setError(null)
				setPending(false)
			}
		}
		catch (err) {
			if (!cancelled) {
				console.log(err.message)
				setError(err.message)
				setPending(false)
			}
		}
	}

	useEffect(() => {
    return () => { setCancelled(true) }
  }, [])

	return { logout, error, pending }
}