import { useEffect, useState } from 'react'
import { fireauth, timestamp } from '../firebase/config'
import { useAuthContext } from './useAuthContext'
import { useFirestore } from './useFirestore'

export const useSignup = () => {
	const [cancelled, setCancelled] = useState(false)
	const [error, setError] = useState(null)				// Error value
	const [pending, setPending] = useState(false)		// Is signup pending?
	const { dispatch } = useAuthContext()

	const { addDocument, response } = useFirestore('users')

	const signupEmail = async (email, password, displayName) => {
		setError(null)
		setPending(true)

		try {
			// sign up the user with email
			const resp = await fireauth.createUserWithEmailAndPassword(email, password)

			if (!resp) {
				throw new Error('Could not complete signup.')
			}

			// add display name to user
			//await resp.user.updateProfile({ displayName: displayName })

      const created = timestamp.fromDate(new Date())
      const updated = created

			const data = {
				created,
				updated,
				displayName,
				catchPhrase: '',
				thumbnail: ''
			}
			await addDocument(data, resp.user.uid)

			// dispatch login action
			dispatch({ type: 'LOGIN', payload: resp.user })

			if (!cancelled) {
				setError(null)
				setPending(false)
			}
		}
		catch (err) {
			if (!cancelled) {
				console.log('caught error: ' + err.message)
				setError(err.message)
				setPending(false)	
			}
		}
	}

  useEffect(() => {
    return () => { setCancelled(true) }
  }, [])

	return { signupEmail, error, pending }
}
