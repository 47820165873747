// Imports
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// Pages and Components
import Home from './pages/pageHome'
import Login from './pages/pageLogin'
import Signup from './pages/pageSignup'
import Profile from './pages/pageProfile'
import Friends from './pages/pageFriends'
import Projects from './pages/pageProjects'
import Project from './pages/pageProject'
import Test from './pages/pageTest'
import Navbar from './components/Navbar'

import PhotoTask from './pages/PhotoTask/pagePhotoTask'

function App() {
  const { user, ready } = useAuthContext()

  return (
    <div className="App">
      { ready && (
        <BrowserRouter>
          <Navbar />

          <div className='content'>

            <Routes>
              <Route path="/" element={
                user ? (
                  <Home />
                ) : (              
                  <Navigate replace to="/login" />
                )
              } />

              <Route path="/login" element={
                !user ? (
                  <Login />
                ) : (              
                  <Navigate replace to="/" />
                )
              } />

              <Route path="/signup" element={
                !user ? (
                  <Signup />
                ) : (
                  <Navigate replace to="/" />
                )
              } />

              <Route path="/profile" element={
                user ? (
                  <Profile />
                ) : (
                  <Navigate replace to="/" />
                )
              } />

              <Route path="/friends" element={
                user ? (
                  <Friends />
                ) : (
                  <Navigate replace to="/" />
                )
              } />

              <Route path="/projects" element={
                user ? (
                  <Projects />
                ) : (
                  <Navigate replace to="/" />
                )
              } />

              <Route path="/project/:pid" element={
                user ? (
                  <Project />
                ) : (
                  <Navigate replace to="/" />
                )
              } />

              <Route path="/test" element={
                user ? (
                  <Test />
                ) : (
                  <Navigate replace to="/" />
                )
              } />

              <Route path="/phototask" element={
                  user ? (
                    <PhotoTask />
                  ) : (
                    <Navigate replace to="/" />
                  )
                } />
              </Routes>

          </div>
          
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
