import { useEffect, useState, useRef } from "react"
import { firestore } from "../firebase/config"

export const useCollection = (collection, _query, _order, limit) => {
  const [documents, setDocuments] = useState(null)
  const [error, setError] = useState(null)

  const query = useRef(_query).current  // to avoid infinite loop due to array reference
  const order = useRef(_order).current  // to avoid infinite loop due to array reference

  useEffect(() => {
    let ref = firestore.collection(collection)

    if (query) {
      ref = ref.where(...query)
    }
    if (order) {
      ref = ref.orderBy(...order)
    }
    if (limit) {
      ref = ref.limit(limit)
    }

    const unsub = ref.onSnapshot((snapshot) => {
      let results = []
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id })
      })

      setDocuments(results)
      setError(null)
    }, (err) => {
      console.log(err)
      setError('could not fetch the data')
    })

    return () => { unsub() }

  }, [collection, query, order])

  return { documents, error }
}
