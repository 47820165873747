
export default function PhotoTask() {

	/*
		Concept:
		- Ability to create tasks
			- Tasks have: name, labels, photo, status, created, updated, completed

		To do:
		- Upload a photo, live or existing
			- Method to take photo live
		- Create tasks per user
	*/

	var imageCapture

	const handleCheck = (e) => {
		const dimensions = document.getElementById("dimensions")
		dimensions.innerHTML = ""
		if (navigator.getUserMedia) dimensions.innerHTML += "Good Check 1<br/>"
		if (navigator.webkitGetUserMedia) dimensions.innerHTML += "Good Check 2<br/>"
		if (navigator.mozGetUserMedia) dimensions.innerHTML += "Good Check 3<br/>"
		if (navigator.msGetUserMedia) dimensions.innerHTML += "Good Check 4<br/>"
	}

	const handleStart = (e) => {

		//Initialize video
		const video = document.getElementById("video")

		// validate video element
		if (navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices
						.getUserMedia({ video: true })
						.then((stream) => {
								video.srcObject = stream

								// Added for high res photos
								const track = stream.getVideoTracks()[0]
								imageCapture = new ImageCapture(track)
						})
						.catch(function(error) {
								console.log("Error!", error)
						});
		}

	}

	const handleStop = (e) => {
		const video = document.getElementById("video")
    let stream = video.srcObject
    let tracks = stream.getTracks()
    tracks.forEach((track) => track.stop())
    video.srcObject = null
	}

	const handleClip = (e) => {
		const video = document.getElementById("video")
		const w = video.videoWidth
		const h = video.videoHeight

		console.log(w, h)

		const dimensions = document.getElementById("dimensions")
		dimensions.innerHTML = w + "x" + h

		var canvas = document.createElement('canvas')
		canvas.width = w
		canvas.height = h
		var context = canvas.getContext('2d')
		context.drawImage(video, 0, 0, w, h)

		// Preview
		var clip = document.getElementById('clip')
		clip.width = w
		clip.height = h
		var clipContext = clip.getContext('2d')
		clipContext.drawImage(canvas, 0, 0, w, h)
	}	

	const handleClear = (e) => {
		var clip = document.getElementById('clip')
		var clipContext = clip.getContext('2d')
		clipContext.clearRect(0, 0, clip.width, clip.height)
	}

	const handleFrame = (e) => {
		imageCapture.grabFrame()
		.then(imageBitmap => {
			var w = imageBitmap.width
			var h = imageBitmap.height

			const dimensions = document.getElementById("dimensions")
			dimensions.innerHTML = w + "x" + h

			var canvas = document.getElementById('frame')
			canvas.width = w
			canvas.height = h
			var context = canvas.getContext('2d')
			context.drawImage(imageBitmap, 0, 0, w, h)
		})
		.catch(error => console.log(error))
	}

	const handleSnap = (e) => {
		imageCapture.takePhoto()
		.then(blob => createImageBitmap(blob))
		.then(imageBitmap => {
			var w = imageBitmap.width
			var h = imageBitmap.height

			const dimensions = document.getElementById("dimensions")
			dimensions.innerHTML = w + "x" + h
	
			var canvas = document.getElementById('snap')
			canvas.width = w
			canvas.height = h
			var context = canvas.getContext('2d')
			context.drawImage(imageBitmap, 0, 0, w, h)
		})
		.catch(error => console.log(error))
	}	

	const handleCapture = (e) => {
		var input = document.getElementById("captureInput")
		var capture = document.getElementById("capture")
		var file = input.files[0]
		const reader = new FileReader()
		var image = new Image()

		reader.addEventListener("load", () => {
			image.src = reader.result
		}, false)

		image.addEventListener("load", () => {
			var w = image.width
			var h = image.height
			capture.width = w
			capture.height = h
			var context = capture.getContext('2d')
			context.drawImage(image, 0, 0)
		}, false)

		if (file) {
			reader.readAsDataURL(file)
		}
	}


	
	return (<div>
	
		<h2>PhotoTask</h2>

		<video id="video" autoPlay></video><br/>
		<div className="items-h">
			<button onClick={handleCheck}>Check</button>
			<button onClick={handleStart}>Start</button>
			<button onClick={handleStop}>Stop</button>
			<button onClick={handleClip}>Clip</button>
			<button onClick={handleFrame}>Frame</button>
			<button onClick={handleSnap}>Snap</button>
			<button onClick={handleClear}>Clear</button>
		</div>
		<span id="dimensions">Dimensions</span>
		<br/>
		<canvas id="clip"></canvas>
		<canvas id="frame"></canvas>
		<canvas id="snap"></canvas>

		<h4>File Method:</h4>
		<input id="captureInput" type="file" accept="image/*;capture=camera" onChange={handleCapture} />
		<br/>
		<canvas id="capture"></canvas>

	</div>)
}



