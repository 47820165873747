import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyDUODJydX9ovuxoYwt3TTNDeIsvfxSJZFw",
  authDomain: "reyorozco-web.firebaseapp.com",
  projectId: "reyorozco-web",
  storageBucket: "reyorozco-web.appspot.com",
  messagingSenderId: "636415666695",
  appId: "1:636415666695:web:ebc105b998b7cf743918a2",
  measurementId: "G-6ZJLNHQP8S"
}

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const firestore = firebase.firestore()
const fireauth = firebase.auth()
fireauth.GoogleAuthProvider = new firebase.auth.GoogleAuthProvider()
const firestorage = firebase.storage()
const firefunctions = firebase.functions()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { firestore, fireauth, firestorage, firefunctions, timestamp }
