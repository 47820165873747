// imports
import { useState } from 'react'
import FormElement from '../components/FormElement'
import { useLogin } from '../hooks/useLogin'

// page
export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { loginEmail, loginGoogle, error, pending } = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()
    loginEmail(email, password)
  }

  const handleGoogle = (e) => {
    e.preventDefault()
    loginGoogle()
  }

  return (<div className='short'>

    <h2>Login With Email</h2>

    <form onSubmit={handleSubmit}>
      <FormElement label="email">
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </FormElement>
      <FormElement label="password">
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </FormElement>
      
      { !pending && <button>Login</button>}
      { pending && <button disabled>Logging In...</button>}
      { error && <p className='notify_err'>{error}</p>}
    </form>

    <h2>Login Alternatives</h2>
    <center>
      { !pending && <button onClick={handleGoogle}>Login With Google</button>}
      { pending && <button disabled>Logging In...</button>}
    </center>
    { error && <p className='notify_err'>{error}</p>}

  </div>)
}
