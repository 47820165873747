import { useState, useEffect } from 'react'
import FormElement from '../components/FormElement'
import { firestorage } from '../firebase/config'
import { useAuthContext } from '../hooks/useAuthContext'
import { useFirestore } from '../hooks/useFirestore'

export default function Profile() {
  const { user, profile } = useAuthContext()
  const { setMergeDocument, response } = useFirestore('users')

  const [displayName, setDisplayName] = useState('')
  const [catchPhrase, setCatchPhrase] = useState('')
  
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailError, setThumbnailError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    let updates = {
      displayName,
      catchPhrase
    }

    if (thumbnail) {
      const uploadPath = 'thumbnails/' + user.uid + '/' + thumbnail.name
      const imageStorage = await firestorage.ref(uploadPath).put(thumbnail)
      const imageUrl = await imageStorage.ref.getDownloadURL()

      updates = {
        ...updates,
        thumbnail: imageUrl
      }
    }
    setMergeDocument(user.uid, updates)
  }

  const handleFileChange = (e) => {
    setThumbnail(null)
    let selected = e.target.files[0]

    if (!selected) {
      setThumbnailError('Please select a file.')
      return
    }
    else {
      if (!selected.type.includes('image')) {
        setThumbnailError('File must be an image.')
        return
      }
      if (selected.size > 100000) {
        setThumbnailError('Image file size must be less than 100kb')
        return
      }  
    }
    setThumbnailError(null)
    setThumbnail(selected)
  }

  useEffect(() => {
    if (profile) {
      setDisplayName(profile.displayName)
      setCatchPhrase(profile.catchPhrase)
      setThumbnailUrl(profile.thumbnail)
    }
  }, [user, profile])  

	return (<div className='short'>

    <h2>Profile</h2>

    <form onSubmit={handleSubmit}>
      <FormElement label="email">
        <input 
          type="email" 
          disabled
          value={user.email}
        />
      </FormElement>
      <FormElement label="display name">
        <input 
          onChange={(e) => setDisplayName(e.target.value)}
          type="text"
          value={ displayName }
        />
      </FormElement>
      <FormElement label="catch phrase">
        <input
          onChange={(e) => setCatchPhrase(e.target.value)}
          type="text"
          value={ catchPhrase }
        />
      </FormElement>
      <FormElement label="thumbnail">
        { thumbnailUrl == '' && <img src="logo192.png" height="64" width="64" />}
        { thumbnailUrl && <img src={thumbnailUrl} height="64" width="64" /> }
        <input 
          type="text"
        />       
        <input 
          type="file"
          onChange={handleFileChange}
        />
        { thumbnailError && <p className='notify_err'>{thumbnailError}</p>}
      </FormElement>

      {/*
      { !pending && <button className="btn">Update</button>}
      { pending && <button className='btn' disabled>Updating...</button>}
      { error && <p className='notify_err'>{error}</p>}
      */}
      <button>Update</button>

    </form>

  </div>)
}
