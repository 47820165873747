// imports
import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'

// styles
import styles from './Navbar.module.css'
import Thumbnail from './Thumbnail'

// Component
export default function Navbar() {
  const { user, profile } = useAuthContext()
  const { logout } = useLogout()

  return (<>

    <nav className={styles.navbar1}>
      <ul>

        <li className={styles.title}>
          <img src="/lifesync_white.png" />&nbsp;&nbsp;
          <Link to="/">LifeSync</Link>
        </li>

        { !user && (
          <>
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/signup">Signup</Link></li>
          </>
        )}

        { user && (
          <>
            { profile?.displayName && <li>{profile.displayName}</li>}
            <li><Thumbnail uid={user.uid} /></li>
            <li><Link to="/profile">Profile</Link></li>
            <li><span onClick={logout}>Logout</span></li>
          </>
        )}
        
      </ul>
    </nav>
    <nav className={styles.navbar2}>
      <ul>

      { user && (
          <>
            <li><Link to="/friends">Friends</Link></li>
            <li><Link to="/projects">Projects</Link></li>
            <li><Link to="/test">Test</Link></li>
            <li><Link to="/phototask">PhotoTask</Link></li>
          </>
      )}

      </ul>
    </nav>

  </>)
}
