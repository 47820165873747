import { useEffect, useState } from "react"
import FormElement from '../components/FormElement'
import Notice from "../components/Notice"
import { useAuthContext } from "../hooks/useAuthContext"
import { useFirestore } from "../hooks/useFirestore"

export default function ProjectsForm() {
  const { user, profile } = useAuthContext()
  const { addDocument, response } = useFirestore('projects')
  const { updateDocument } = useFirestore('users')
  const [name, setName] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (user && profile) {
      const roles = {}
      roles[user.uid] = "owner"

      // Create project
      const datProj = {
        name,
        roles
      }
      const resProj = await addDocument(datProj)

      // Create project reference for user
      let projects = profile.projects
      if (!projects) { projects = [] }
      projects.push(resProj.id)

      const updates = {
        projects,
      }
      await updateDocument(user.uid, updates)
    }
  }

  useEffect(() => {
    if (response.success) {
      setName('')
    }
  }, [response.success])

  return (
    <form onSubmit={handleSubmit}>
      <FormElement label="project name">
        <input
          type="text"
          required
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        { response.error && <Notice type="error">{response.error}</Notice> }
        { response.success && <Notice type="success">Project Added!</Notice> }
      </FormElement>
      <button>Create Project</button>
    </form>  
  )
}
