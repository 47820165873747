// imports
import { createContext, useEffect, useReducer, useRef, useState } from 'react'
import { fireauth } from '../firebase/config'
import { useDocument } from '../hooks/useDocument'

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
	const [firstScan, setFirstScan] = useState(true)
	const [uid, setUid] = useState('')
  const { document: profile, error: errProfile } = useDocument('users', uid)

	const authReducer = (state, action) => {
		//console.log("auth reducer:", action)

		switch (action.type) {
			case 'READY':
				setUid(action.payload?.uid)
				return { ...state, user: action.payload, ready: true }
			case 'LOGIN':
				setUid(action.payload?.uid)
				return { ...state, user: action.payload }
			case 'LOGOUT':
				setUid('')
				return { ...state, user: null, profile: null }
			case 'PROFILE':
				return { ...state, profile: action.payload }	
			default:
				return state
		}
	}
	const [state, dispatch] = useReducer(authReducer, {
		user: null,
		profile: null,
		ready: false
	})

	//console.log('AuthContext state:', state)

	useEffect(() => {
		if (firstScan) {
			const unsub = fireauth.onAuthStateChanged((user) => {
				dispatch({ type: 'READY', payload: user })
				unsub()
			})
			setFirstScan(false)
		}

		if (profile) dispatch({ type: 'PROFILE', payload: profile })

	}, [profile])

	return (
		<AuthContext.Provider value={{ ...state, dispatch }}>
			{ children }
		</AuthContext.Provider>
	)
}
